import { useStaticQuery, graphql } from "gatsby"

const ALL_DROPZONE_IMAGES = graphql`
  {
    pdfThumbnail: file(relativePath: { eq: "pdf.png" }) {
      childImageSharp {
        fixed(width: 48) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export const useDropzoneImages = () => {
  const data = useStaticQuery(ALL_DROPZONE_IMAGES)

  return data
}
