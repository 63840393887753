import React, { Fragment, useState } from "react"
import { Link, navigate } from "gatsby"
import { Formik, Form } from "formik"
import classNames from "classnames"

import Button from "elements/Button"
import FormInput from "elements/Form/FormInput"
import Message from "elements/Message"

import { initialValues, validationSchema } from "./utils/signInSchema"
import { handleEmailLogin } from "./services/authActions"
import { hasAuthUser } from "./services/authUser"
import { isBrowser } from "../../services/general"

const SignIn = ({ location }) => {
  const [loading, setLoading] = useState(false)
  if (hasAuthUser() && isBrowser()) {
    navigate(location?.state?.pathname || `/profile`)
  }

  const handleSubmit = (values, { setErrors, resetForm }) => {
    setLoading(true)
    handleEmailLogin({
      values,
      setErrors,
      resetForm,
      location,
      errorCallback: () => setLoading(false),
    })
  }

  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors }) => (
          <Form>
            <FormInput
              name="email"
              label="Email"
              value={values.email}
              placeholder="juan@mail.com"
              isRequired
            />
            <FormInput
              name="password"
              label="Password"
              type="password"
              placeholder="********"
              value={values.password}
              isRequired
            />
            <p className="is-size-6 pb-1 pt-0">
              <Link to="/forgot-password">Forgot password</Link>
            </p>

            {errors.login && (
              <Message color="warning">{errors.login.message}</Message>
            )}
            <Button
              type="submit"
              color="primary"
              disabled={loading}
              className={classNames({ "is-loading": loading })}
              isFullwidth
            >
              Sign In
            </Button>
          </Form>
        )}
      </Formik>
      <section>
        <p className="has-text-centered is-size-6 pt-1">
          Don't have an account yet?{" "}
          <Link to="/doctor/sign-up">Register as a partner doctor</Link>
        </p>
      </section>
    </Fragment>
  )
}

export default SignIn
