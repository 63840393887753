import React from "react"
import { Router } from "@reach/router"

import DoctorRoute from "components/Auth/DoctorRoute"
import ViewPatient from "components/Doctor/ViewPatient"
import SignIn from "components/Auth/SignIn"

// TODO: Convert to single page with router

export default () => {
  return (
    <Router>
      <DoctorRoute
        path="/patients/view"
        seoTitle="Patient Profile"
        component={ViewPatient}
        title="Patient Profile"
        subtitle=""
      />
      <SignIn path="/sign-in" />
    </Router>
  )
}
