import React, { useContext, Fragment, useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { navigate, Link } from "gatsby"
import classNames from "classnames"

import PatientCard from "./PatientCard"
import EprescriptionsSection from "./ViewPatient/EprescriptionsSection"

import { AppContext } from "../../context/AppContext"
import { documents, eprescription } from "../../context/AppReducer"
import { getPatientEprescriptions } from "../Eprescription/services/eprescription"
import { getAuthUser } from "../Auth/services/authUser"

import styles from "./utils/doctor.module.scss"

const ViewPatient = () => {
  const { state, dispatch } = useContext(AppContext)
  const [eprescriptions, setEprescriptions] = useState([])
  const [uploadedEprescriptions, setUploadedEprescriptions] = useState([])

  useEffect(() => {
    const fetchEprescriptions = async () => {
      let userData = getAuthUser()?.userData
      const fetchedEprescriptions = await getPatientEprescriptions({
        patientUid: state?.patient?.id,
        doctorUid: userData?.id,
      })
      let tempEprescriptions = [...fetchedEprescriptions]
      let tempIssuedEprescriptions = tempEprescriptions.filter(
        eprescription => !eprescription.uploadedFile
      )
      let tempUploadedEprescriptions = tempEprescriptions.filter(
        eprescription => eprescription.uploadedFile
      )
      setEprescriptions(tempIssuedEprescriptions)
      setUploadedEprescriptions(tempUploadedEprescriptions)
    }

    if (!state?.patient?.id) navigate("/patients")
    else fetchEprescriptions()
  }, [state])

  useEffect(() => {
    dispatch({ type: eprescription.RESET_EPRESCRIPTION })
    dispatch({ type: documents.SAVE_DOCUMENTS, payload: [] })
  }, [dispatch])

  return (
    <Fragment>
      <div className="buttons is-right">
        <Link
          to="/patients/edit"
          className={classNames(
            "button is-small is-outlined",
            styles["patient__editButton"]
          )}
        >
          <span className="icon">
            <FontAwesomeIcon icon={faEdit} />
          </span>{" "}
          <span>Edit</span>
        </Link>
      </div>
      <PatientCard patient={state?.patient} />
      <section className="my-3">
        <EprescriptionsSection
          eprescriptions={eprescriptions}
          emptyMessage={"You did not issue an ePrescription to this patient."}
          sectionTitle="Issued ePrescriptions"
        />
        <EprescriptionsSection
          eprescriptions={uploadedEprescriptions}
          emptyMessage={"You did not issue an ePrescription to this patient."}
          sectionTitle="Uploaded ePrescriptions"
        />
      </section>
      <p className="has-text-centered mb-3">
        <Link className="button is-primary is-medium" to="/patients">
          Back to My Patients
        </Link>
      </p>
    </Fragment>
  )
}

export default ViewPatient
