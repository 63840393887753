import moment from "moment"
import { GATSBY_BASE_URL } from "gatsby-env-variables"
import { encrypt } from "../../../Auth/services/crypto"

export const eprescriptionPatientMessageTemplate = ({
  eprescriptionId,
  doctor,
  patient,
}) => {
  return `[${moment().format("YYYY/MM/DD hh:mm:ss A")}] Hi ${patient?.firstName
    } ${patient?.lastName}. Dr. ${doctor?.firstName} ${doctor?.lastName
    } has nominated you for the Semaglutide (Ozempic®) Early Experience Program. Under this program, you will get early access to Semaglutide (Ozempic®), a novel therapeutic option for diabetes management. To join the program, fill out the enrollment form at ${GATSBY_BASE_URL}view/?e=${encodeURIComponent(
      encrypt({ data: eprescriptionId })
    )}. \n\nSince the program has limited slots, enrollments will be taken on a first come, first served basis. Enrollments are not considered final until they have been verified by our coordinators.\n\nFeel free to reach us at ozempic@medgrocer.com or 0968 230 6545 for any questions. \n– MedGrocer`
}
