import firebase from "firebase"
import { pdf } from "@react-pdf/renderer"
import { GATSBY_ENV } from "gatsby-env-variables"
import FireStoreParser from "firestore-parser"

import { handleError } from "../../../services/handleError"
import { isBrowser } from "services/general"
import { sendMessage } from "services/telerivet"
import { sendToZendesk } from "services/zendeskService"
import { b64toBlob } from "../../../services/zendeskService"
import { eprescriptionZendeskTemplate } from "../templates/eprescriptionZendeskTemplate"
import { eprescriptionPatientMessageTemplate } from "../utils/templates/eprescriptionPatientMessageTemplate"
import { hasAuthUser } from "../../Auth/services/authUser"
import { firebaseApi } from "../../../services/firebase/firebaseApi"
import { generateJWT } from "../../../services/jwt"
import {
  createRelationshipDocument,
  hasDoctorPatientRelationship,
} from "../../Doctor/services/relationships"

export const NEW_PRESCRIPTION = "new"
export const EDIT_PRESCRIPTION = "edit"

const convertBlobToBase64 = blob => {
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result)
    }
  })
}

export const getEprescription = async ({ eprescriptionId }) => {
  if (isBrowser()) {
    if (hasAuthUser()) {
      const eprescription = await firebase
        .firestore()
        .collection("prescriptions")
        .doc(eprescriptionId)
        .get()

      return { ...eprescription.data(), id: eprescription.id }
    } else {
      const jwtObject = await generateJWT()
      const accessToken = jwtObject?.data?.access_token
      const request = await firebaseApi({ accessToken: accessToken }).get(
        `prescriptions/${eprescriptionId}`
      )
      const eprescriptionData = FireStoreParser(request?.data?.fields)
      return { ...eprescriptionData, id: eprescriptionId }
    }
  }
}

export const getPatientEprescriptions = async ({ patientUid, doctorUid }) => {
  if (isBrowser()) {
    const filteredEprescriptions = await firebase
      .firestore()
      .collection("prescriptions")
      .where("patientUid", "==", patientUid)
      .where("doctorUid", "==", doctorUid)
      .get()

    let fetchedEprescriptions = []
    filteredEprescriptions.forEach(doctorPatient => {
      fetchedEprescriptions.push({
        ...doctorPatient.data(),
        id: doctorPatient.id,
      })
    })

    return fetchedEprescriptions
  }
}

export const getDailyIntakeString = ({ dailyIntake }) => {
  const repetition = [
    "",
    "Once",
    "Twice",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ]
  return `${
    dailyIntake > repetition.length - 1 ? dailyIntake : repetition[dailyIntake]
  }${dailyIntake < 3 ? "" : " times"} a day`
}

export const saveEprescription = async ({ eprescription, documents }) => {
  if (isBrowser()) {
    let fileURL = ""
    if (documents?.length > 0) {
      let uploadedPrescription =
        documents?.find(document =>
          document?.name?.toLowerCase()?.startsWith("rx")
        ) || documents[0]
      let storageRef = firebase.storage().ref()
      let prescriptionRef = storageRef.child(`erx/${uploadedPrescription.name}`)
      let b64Data = uploadedPrescription?.path?.split(",")[1]
      let contentType = uploadedPrescription?.path
        ?.split(",")[0]
        ?.match(/:(.*?);/)[1]

      let newUploadedPrescription = b64toBlob(b64Data, contentType)
      let storageResponse = await prescriptionRef.put(newUploadedPrescription)
      fileURL = await storageResponse.ref.getDownloadURL()
    }

    const createdEprescription = await firebase
      .firestore()
      .collection("prescriptions")
      .add({ ...eprescription, uploadedFile: fileURL })

    return createdEprescription
  }
}

export const sendEprescription = async ({
  eprescription,
  patient,
  doctor,
  prescriptionFile,
  documents,
  errorCallback,
  callback,
}) => {
  try {
    let eprescriptionId = eprescription.id
    if (!eprescriptionId) {
      eprescriptionId = await saveEprescription({ eprescription, documents })
      eprescriptionId = eprescriptionId.id
    }

    let isTest = GATSBY_ENV !== "production"

    let tags = ["eprescription", "novo_nordisk", "ozempic"]
    if (isTest) tags.push("test")

    let subject = `${isTest ? "[TEST] " : ""} ePrescription of ${
      patient?.firstName
    } ${patient?.lastName} issued by ${doctor?.firstName} ${doctor?.lastName}`

    let requestBody = {
      type: "request",
      tags,
      subject,
      requester: {
        name: `${doctor?.firstName} ${doctor?.lastName}`,
        email: doctor?.email,
      },
      comment: {
        body: eprescriptionZendeskTemplate({
          eprescriptionId,
          eprescription,
          doctor,
          patient,
        }),
      },
    }

    let fetchedRelationship = await hasDoctorPatientRelationship({
      doctorUid: doctor?.id,
      patientUid: patient?.id,
    })

    if (!fetchedRelationship?.id)
      createRelationshipDocument({
        doctorUid: doctor?.id,
        patientUid: patient?.id,
        patientData: patient,
      })

    await sendToZendesk(requestBody, [prescriptionFile] || [])

    await sendMessage({
      message: eprescriptionPatientMessageTemplate({
        eprescriptionId,
        doctor,
        patient,
      }),
      recipient: patient?.mobileNumber,
      callback,
    })
  } catch (error) {
    let errorMessage =
      "There was an error sending this ePrescription. Please try again."
    handleError({ error, errorCallback, errorMessage })
  }
}

export const convertPDFToFileObject = async ({ document, filename }) => {
  let blob = await pdf(document).toBlob()
  let path = await convertBlobToBase64(blob)
  return {
    path,
    name: filename,
    filename: filename,
    oldname: filename,
    type: "application/pdf",
  }
}
