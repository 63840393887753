import firebase from "firebase"
import { isBrowser } from "../../../services/general"

export const createRelationshipDocument = async ({
  doctorUid,
  patientUid,
  patientData,
}) => {
  if (isBrowser()) {
    let relationshipDocument = {
      doctorUid,
      patientUid,
      patient: patientData,
    }

    const createdRelationship = await firebase
      .firestore()
      .collection("doctorPatients")
      .add(relationshipDocument)

    return createdRelationship
  }
}

export const hasDoctorPatientRelationship = async ({
  doctorUid,
  patientUid,
}) => {
  if (isBrowser()) {
    const filteredRelationships = await firebase
      .firestore()
      .collection("doctorPatients")
      .where("doctorUid", "==", doctorUid)
      .where("patientUid", "==", patientUid)
      .get()

    let fetchedRelationship = {}
    filteredRelationships.forEach(relationship => {
      fetchedRelationship = { ...relationship.data(), id: relationship.id }
    })

    return fetchedRelationship
  }
}
