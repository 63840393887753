import React from "react"
import classNames from "classnames"

import styles from "./utils/doctor.module.scss"
import { getAge } from "../../services/date"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBirthdayCake,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons"

const PatientCard = ({ patient, handleClick }) => {
  let birthDate = patient?.birthday?.date?.value || patient?.birthday?.date
  let birthMonth = patient?.birthday?.month?.value || patient?.birthday?.month
  let birthYear = patient?.birthday?.year

  return (
    <div
      className={classNames("notification is-light", {
        "is-clickable": !!handleClick,
      })}
      onClick={() => {
        if (handleClick) handleClick({ route: "/patients/view" })
      }}
      onKeyDown={event => {
        if (handleClick && event.key === "Enter")
          handleClick({ route: "/patients/view" })
      }}
      role="button"
      tabIndex={0}
    >
      <h5
        className={classNames(
          "mt-0 pb-1 is-flex is-justify-content-space-between is-align-items-center",
          styles["patient__name"]
        )}
      >
        <span>
          {patient?.lastName}, {patient?.firstName}
        </span>
      </h5>
      <p className="is-size-6">
        {patient?.sex} | {getAge({ dateObject: patient?.birthday })} years old
      </p>
      <p className="is-size-6">
        <FontAwesomeIcon icon={faBirthdayCake} />{" "}
        <span className="ml-1">
          {birthDate} {birthMonth} {birthYear}
        </span>
      </p>
      <p className="is-size-6">
        <FontAwesomeIcon icon={faPhone} />{" "}
        <span className="ml-1">{patient?.mobileNumber}</span>
      </p>
      <p className="is-size-6">
        <FontAwesomeIcon icon={faEnvelope} />{" "}
        <span className="ml-1">{patient?.email}</span>
      </p>
    </div>
  )
}

export default PatientCard
