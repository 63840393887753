import React from "react"

import Section from "elements/Section"
import PatientEprescription from "./PatientEprescription"
import { getMomentDate } from "../../../services/date"

const EprescriptionsList = ({ eprescriptions, emptyMessage }) => {
  if (eprescriptions?.length > 0)
    return eprescriptions
      ?.sort((a, b) =>
        getMomentDate({ dateObject: b.expirationDate }).diff(
          getMomentDate({ dateObject: a.expirationDate })
        )
      )
      ?.map(eprescription => (
        <PatientEprescription eprescription={eprescription} />
      ))
  return <p className="has-text-centered mt-3 has-text-grey">{emptyMessage}</p>
}

const EprescriptionsSection = ({
  eprescriptions,
  emptyMessage,
  sectionTitle,
}) => {
  if (eprescriptions?.length > 0)
    return (
      <Section title={sectionTitle}>
        <EprescriptionsList
          eprescriptions={eprescriptions}
          emptyMessage={emptyMessage}
        />
      </Section>
    )
  return null
}

export default EprescriptionsSection
