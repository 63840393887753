import React from "react"
import Img from "gatsby-image"
import classNames from "classnames"

import styles from "./utils/elements.module.scss"
import { useDropzoneImages } from "elements/hooks/useDropzoneImages"

const FileURLPreview = ({ file }) => {
  let fileName = file.split(".com/o/")[1].split("?alt=media")[0]
  const pdfThumbnail = useDropzoneImages().pdfThumbnail.childImageSharp.fixed
  const filePreview = fileName.includes(".pdf") ? (
    <Img fixed={pdfThumbnail} />
  ) : (
    <img
      src={file}
      className={classNames(
        "image is-28x28",
        styles["documentRow__imgThumbnail"]
      )}
      alt="attachment"
    />
  )

  return (
    <div className="is-flex is-align-items-center">
      {filePreview}
      <p className="ml-1">{fileName}</p>
    </div>
  )
}

export default FileURLPreview
