import { GATSBY_BASE_URL } from "gatsby-env-variables"
import { encrypt } from "../../Auth/services/crypto"

export const eprescriptionZendeskTemplate = ({
  eprescriptionId,
  eprescription,
  doctor,
  patient,
}) => {
  let prescriptionDetails = eprescription.medicines
    .map(medicine => {
      return `SKU: ${medicine.productTitle}\nQuantity: ${
        medicine.quantity
      }\nIndication: ${
        eprescription?.indication === "Other (please specify)"
          ? eprescription?.otherIndication
          : eprescription?.indication?.value || eprescription?.indication
      }\nDaily Intake: ${medicine?.dailyIntake ||
        "N/A"}\nOther Notes: ${eprescription?.patientInstructions || "N/A"}\n`
    })
    .join("\n")

  let birthmonth = patient?.birthday?.month?.value || patient?.birthday?.month
  let birthdate = patient?.birthday?.date?.value || patient?.birthday?.date

  return `First Name: ${patient?.firstName}\nLast Name: ${
    patient?.lastName
  }\nMobile number: ${patient?.mobileNumber}\nEmail: ${
    patient?.email
  }\nBirthday: ${birthmonth} ${birthdate} ${patient?.birthday?.year}\nSex: ${
    patient?.sex
  }\n---\nPrescribing Doctor\nFirst Name: ${doctor?.firstName}\nLast Name: ${
    doctor?.lastName
  }\n---\nPrescription Details\n${prescriptionDetails}\nCurrent medication patient is taking: ${
    eprescription?.concurrentMedication?.length > 0
      ? eprescription?.concurrentMedication.join(", ")
      : "N/A"
  }\nOther Recommendations: ${eprescription?.otherRecommendations ||
    "N/A"}\nLink to eRx: ${GATSBY_BASE_URL}view/?e=${encodeURIComponent(
    encrypt({ data: eprescriptionId })
  )}`
}
