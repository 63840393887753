import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { isBrowser } from "services/general"
import { getAuthUser, hasAuthUser } from "./services/authUser"
import {
  GATSBY_AWS_S3_BUCKET_NAME,
  GATSBY_FIREBASE_DOCTOR_ROLE_ID,
} from "gatsby-env-variables"

import Layout from "layout/Layout"
import Container from "layout/Container"

const DoctorRoute = ({
  component: Component,
  location,
  title,
  subtitle,
  ...rest
}) => {
  useEffect(() => {
    let userData = getAuthUser()?.userData
    let userRoles = userData?.roles || []
    let doctorRole = userRoles.find(
      roleObject =>
        roleObject.subdomain === GATSBY_AWS_S3_BUCKET_NAME &&
        roleObject.role === GATSBY_FIREBASE_DOCTOR_ROLE_ID
    )
    if (!doctorRole?.active) navigate("/profile")
  }, [])

  if (isBrowser() && !hasAuthUser()) {
    navigate(`/sign-in`, { state: { ...location } })
    return null
  }

  return (
    <Layout title={title} subtitle={subtitle} {...rest}>
      <Container isCentered {...rest}>
        <Component {...rest} location={location} />
      </Container>
    </Layout>
  )
}

export default DoctorRoute
