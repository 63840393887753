import moment from "moment"

export const getAge = ({ dateObject }) => {
  let month = dateObject?.month?.value || dateObject?.month
  let date = dateObject?.date?.value || dateObject?.date

  return moment().diff(`${month} ${date} ${dateObject?.year}`, "years")
}

export const isFutureDate = ({ dateObject }) => {
  let month = dateObject?.month?.value || dateObject?.month
  let date = dateObject?.date?.value || dateObject?.date

  return new Date() < moment(`${month} ${date} ${dateObject?.year}`).toDate()
}

export const getMomentDate = ({ dateObject }) => {
  let month = dateObject?.month?.value || dateObject?.month
  let date = dateObject?.date?.value || dateObject?.date

  return moment(`${month} ${date} ${dateObject?.year}`)
}
