import React from "react"
import { navigate } from "gatsby"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPrescription } from "@fortawesome/free-solid-svg-icons"

import FileURLPreview from "elements/FileURLPreview"

import { isFutureDate } from "services/date"
import styles from "../utils/doctor.module.scss"
import { encrypt } from "../../Auth/services/crypto"

const PatientEprescription = ({ eprescription }) => {
  const handleClick = () => {
    navigate(
      `/view/?e=${encodeURIComponent(encrypt({ data: eprescription.id }))}`
    )
  }
  const expirationMonth =
    eprescription?.expirationDate?.month?.value ||
    eprescription?.expirationDate?.month
  const expirationDate =
    eprescription?.expirationDate?.date?.value ||
    eprescription?.expirationDate?.date

  if (eprescription.uploadedFile) {
    return (
      <div
        className={classNames(
          "box is-shadowless mt-1 is-clickable",
          styles["patient__eprescription"]
        )}
        onClick={handleClick}
        onKeyDown={event => {
          if (handleClick && event.key === "Enter") handleClick()
        }}
        role="button"
        tabIndex={0}
      >
        <FileURLPreview file={eprescription.uploadedFile} />
      </div>
    )
  }

  return (
    <div
      className={classNames(
        "box is-shadowless mt-1 is-clickable",
        styles["patient__eprescription"]
      )}
      onClick={handleClick}
      onKeyDown={event => {
        if (handleClick && event.key === "Enter") handleClick()
      }}
      role="button"
      tabIndex={0}
    >
      <div className="is-flex">
        <div className="mt-1">
          <FontAwesomeIcon
            icon={faPrescription}
            className="has-text-primary"
            size="2x"
          />
        </div>
        <div className="ml-1 is-flex-grow-1">
          {eprescription?.medicines?.map(medicine => (
            <div className="mb-1">
              <p className="is-flex is-justify-content-space-between">
                <span>{medicine?.productTitle}</span>
                <span className="has-text-primary">#{medicine.quantity}</span>
              </p>
              <p className="has-text-grey is-size-6">
                {eprescription?.indication === "Other (please specify)"
                  ? eprescription?.otherIndication
                  : eprescription?.indication}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="is-flex is-align-items-center">
        <p className="is-size-6">
          Validity: {expirationDate} {expirationMonth}{" "}
          {eprescription?.expirationDate?.year}
        </p>
        {!isFutureDate({ dateObject: eprescription?.expirationDate }) && (
          <span class="tag is-primary is-warning ml-1">Expired</span>
        )}
      </div>
    </div>
  )
}

export default PatientEprescription
