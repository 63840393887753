import * as Yup from "yup"

export const initialValues = { email: "", password: "" }

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please input a valid email.")
    .required("This field is required."),
  password: Yup.string().required("This field is required."),
})
